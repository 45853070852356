<script>
import redactor from "../../form/redactor.vue";

export default {
  name: "approverResponseModal",
  components: {
    redactor,
  },
  props: {
    proof: {
      required: true,
    },
    account: {
      required: true,
    },
    commentApiUrl: {
      required: true,
    },
  },
  methods: {
    open: function (mode, approverFeedback) {
      if (this.feedBackReceived !== null || approverFeedback !== null) {
        return;
      }
      this.mode = mode;
      this.visible = true;
    },
    saveProgressAndCancel: function () {
      this.visible = false;
    },
    updateSubmitButton: function () {
      this.$forceUpdate();
    },
    allFilesAcknowledged: function () {
      return this.files.length === 0 || this.files.every(f => f.approvalFeedback);
    },
    saveResponse() {
      let self = this;
      if (this.files.some(f => !f.approvalFeedback)) {
        this.errorMessage = "You cannot approve until you have acknowledged all the files.";
        return;
      }
      if (!this.checkListStatus) {
        this.errorMessage =
            this.getLabel('approver_decision_modal_labels', 'approver_error_msg');
        return;
      }
      let fileApprovalStatus = [];
      this.files.forEach(function (f) {

        if (self.allowApproveIndividualFiles) {
          fileApprovalStatus.push({
            fileId: f.id,
            fileName: f.name,
            approved: f.approvalFeedback == "1",
            approvedWithChanges: f.approvalFeedback == "2",
          });
        } else {
          fileApprovalStatus.push({
            fileId: f.id,
            fileName: f.name,
            approved: self.mode == "1",
            approvedWithChanges: self.mode == "2",
          });
        }
      });

      this.$emit("approverResponded", {
        approvalChoice: this.mode,
        feedBackText: this.feedBackText,
        filesApprovalStatus: fileApprovalStatus,
        // we only want to save the checklist if not rejecting all files
        confirmedChecklistItems: this.mode !== 0 ? self.proofRequirements.map((r) => r.name) : [],
      });
      this.feedBackReceived = true;
      this.visible = false;
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    }
  },
  computed: {
    allowApproveIndividualFiles: function () {
      return this.proof !== null &&
      this.proof.allowApproveIndividualFiles !== undefined
          ? this.proof.allowApproveIndividualFiles
          : false;
    },
    allowApproveWithChanges: function () {
      return this.proof !== null &&
      this.proof.allowApproveWithChanges !== undefined
          ? this.proof.allowApproveWithChanges
          : false;
    },
    files: function () {
      let files = _.get(this, 'proof.currentVersion.versionFiles', [])
      for (let i = 0; i < files.length; i++) {
        files[i].approvalFeedback = 0;
      }
      return files;
    },
    proofRequirements: function () {
      return this.account !== null ? this.account.proofRequirements : [];
    },
    checkListStatus: function () {
      if (this.mode !== 0 && this.proofRequirements.length > 0) {
        let allChecked = true;
        this.proofRequirements.forEach(function (r) {
          if (r.acknowledge === undefined || r.acknowledge === false) {
            allChecked = false;
          }
        });
        return allChecked;
      }
      return true;
    }
  },
  data() {
    return {
      id: this.$A.GetUUID(),
      mode: -1,
      feedBackText: "",
      errorMessage: "",
      feedBackReceived: null,
      visible: false,
    };
  },
};
</script>

<template>
  <!-- Add Note Modal -->
  <b-modal v-model="visible" :id="id" size="lg" hide-header-close>
    <button 
    type="button" 
    class="custom-close" 
    @click="$bvModal.hide(id)">
      ✖
    </button>

    <div class="w-100 text-left">
      <h3 v-if="mode === 0" class="header-section">{{ getLabel('approver_decision_modal_labels', 'reject_modal') }}</h3>
      <h3 v-if="mode === 1" class="header-section">{{ getLabel('approver_decision_modal_labels', 'approve_modal') }}</h3>
      <h3 v-if="mode === 2" class="header-section">{{ getLabel('approver_decision_modal_labels', 'approve_with_changes') }}</h3>
      <h3 v-if="mode === 3" class="header-section">{{ getLabel('approver_decision_modal_labels', 'submit_decision_header') }}</h3>
    </div>

    <!-- Individual File Approval -->
    <div
        class="w-100 text-left"
        style="margin-top: 5px"
        v-if="allowApproveIndividualFiles && mode === 3"
    >
      <p class="description-text text-margin">{{ getLabel('approver_decision_modal_labels', 'approve_individual_file') }}</p>
    </div>

    <div
        class="row"
        v-if="proof !== null && allowApproveIndividualFiles && mode === 3"
    >
      <div class="col-md-12 m-auto text-left">
        <div
            class="file-container"
            v-for="file in files"
            v-bind:key="file.id"
        >
          <div class="file-row">
            <div class="file-info">
              <div class="file-name">
                {{ file.name }}
              </div>
                <span v-if="!file.viewed" class="not-viewed-pill">
                  Not Viewed
                </span>
            </div>
            <select
             class="file-approval-dropdown form-control"
             v-model="file.approvalFeedback"
             @change="updateSubmitButton"
             :class="{ 'placeholder': !file.approvalFeedback }">
              <option value="0" disabled hidden>Select</option>
              <option value="1" style="color: green">{{ getLabel('review_screens_labels', 'approve_btn') }}</option>
              <option
                  value="2"
                  style="color: green"
                  v-if="allowApproveWithChanges"
              >
                {{ getLabel('review_screens_labels', 'approve_with_changes_btn') }}
              </option>
              <option value="3" style="color: red">{{ getLabel('review_screens_labels', 'not_approved_btn') }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- Approval Checklist -->
    <div
        class="w-100"
        v-if="mode !== 0 && proofRequirements.length > 0"
    >
      <p class="description-text">{{ getLabel('approver_decision_modal_labels', 'approver_acknowledge_checklist') }}</p>
    </div>

    <div class="row">
      <div class="pl-3 checklist-container">
        <div
            class="row ashore-toggle mb-2 checklist-item"
            v-if="mode !== 0"
            v-for="item in proofRequirements"
            v-bind:key="item.id"
        >
          <div class="col-2">
            <label class="switch">
              <input type="checkbox" v-model="item.acknowledge"/>
              <div class="slider round"></div>
            </label>
          </div>
          <div class="col-10 px-3 px-lg-1" v-html="item.name"></div>
        </div>
      </div>
    </div>

    <!-- General Feedback -->
    <div class="row mt-3">
      <div class="col-md-12">
        <span class="description-text m-0">{{ getLabel('approver_decision_modal_labels', 'feedback_box') }}</span>
        <textarea
          v-model="feedBackText"
          class="form-control text-area"
          rows="2"
        ></textarea>
      </div>
    </div>

    <div class="w-100 text-center" style="margin-top: 50px">
      <strong class="mt-25" style="color: red">{{ errorMessage }}</strong>
    </div>
    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
            class="gray-btn"
            @click="saveProgressAndCancel()"
        >{{ getLabel('approver_decision_modal_labels', 'cancel_modal_btn') }}
        </b-button
        >
        <b-button
            class="green-btn button"
            v-if="mode === 1"
            :style="{ opacity: checkListStatus ? 1 : 0.5 }"
            @click="saveResponse"
        >{{ getLabel('approver_decision_modal_labels', 'approve_btn') }}
        </b-button
        >
        <b-button
            class="ml-1 green-btn"
            v-if="mode === 2"
            :style="{ opacity: checkListStatus ? 1 : 0.5 }"
            @click="saveResponse"
        >{{ getLabel('approver_decision_modal_labels', 'approve_with_changes_btn') }}
        </b-button
        >
        <b-button
            class="red-btn"
            v-if="mode === 0"
            @click="saveResponse"
        >{{ getLabel('approver_decision_modal_labels', 'not_approved_btn') }}
        </b-button
        >
        <b-button
            class="green-btn individual-file-button"
            v-if="mode === 3"
            :style="{ opacity: (checkListStatus && allFilesAcknowledged()) ? 1 : 0.5 }"
            @click="saveResponse"
        >{{ getLabel('approver_decision_modal_labels', 'submit_decision_btn') }}
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style scoped>
.custom-close {
  position: absolute;
  top: -8px;
  right: 28px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #000;
  cursor: pointer;
}

.header-section {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #252526;
  margin: 0px;
}

.text-margin {
  margin: 20px 0px 10px 0px !important;
}

.placeholder {
  opacity: 0.6;
}

.description-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575759;
  margin: 10px 0px;
}

.file-container {
  border-radius: 4px;
  padding: 8px 16px;
  border: 1px solid #D5D5D8;
  margin-bottom: 10px;
}

.file-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.file-info {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
}

.file-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: #252526;
  word-break: break-word;
  margin-right: 10px;
}

.not-viewed-pill {
  width: 85px;
  height: 20px;
  border-radius: 10px;
  background-color: #FBE2E4;
  padding: 2px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0%;
  text-align: center;
  color: #7D1F27;
  flex-shrink: 0;
  margin-right: 4px;
}

.text-area {
  margin-top: 4px;
  min-height: 105px;
  border-radius: 6px;
  border: 1px solid #B5B5B8;
  background-color: #F9F9F9;
}

.individual-file-button{
  height: 38px;
  border-radius: 6px !important;
  padding: 9px 17px;
  background-color: #019256;
  box-shadow: 0px 1px 2px 0px #080B0D0D !important;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 20px;
  color: #F9F9F9;
}

.review-toggle {
  font-weight: bold;
  padding-bottom: 10px;
}

.review-toggle .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
  margin-right: 10px;
}

.review-toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch {
  margin-right: 2px;
}

.review-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eef2f5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.file-approval-dropdown {
  width: 210px;
  height: 38px;
  gap: 4px;
  border-radius: 6px;
  padding: 9px 13px;
  border: 1px solid #B5B5B8;
  background-color: #F9F9F9;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(/img/selector.svg);
  background-size: 20px;
  background-position: right 16px center;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-top: 8px;
}

.review-toggle .slider:before {
  background-color: #d44d68;
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.review-toggle input:checked .slider {
  background-color: #ffabbc;
}

.review-toggle input:focus + .slider {
  box-shadow: 0 0 1px #dee3ec;
}

.review-toggle input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.review-toggle .slider.round {
  border-radius: 34px;
}

.review-toggle .slider.round:before {
  border-radius: 50%;
}

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  padding: 10px !important;
}

.gray-dark-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: black !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  border-radius: 6px;
  padding: 10px !important;
  background: darkgray;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.5);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  color: #fff !important;
}

.green-btn:hover {
  background-color: #019255 !important;
  opacity: 0.9 !important;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}

.red-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 17px;
  height: 36px;
  background: #D44D68;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  padding: 9px 17px !important;
  margin-left: 10px;
  color: #fff !important;
}

.red-btn:hover {
  background: #D44D68;
  opacity: 0.9;
}

.slider {
  position: absolute;
  cursor: pointer;
  width: 44px;
  height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #006F41;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.slider.round {
  border-radius: 12px;
}

.slider.round:before {
  border-radius: 50%;
}

::v-deep .modal-body{
  padding: 0px 30px ;
}

@media screen and (min-width: 992px) {
  .file-row {
    flex-wrap: nowrap;
  }
  
  .file-info {
    width: auto;
    margin-bottom: 0;
  }
  
  .file-approval-dropdown {
    margin-top: 0;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .file-row {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .file-info {
    margin-bottom: 8px;
  }
  
  .file-approval-dropdown {
    width: 100%;
  }
 
}

@media screen and (max-width: 767px) {
  .file-row {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .file-info {
    margin-bottom: 8px;
    flex-wrap: wrap;
  }
  
  .file-approval-dropdown {
    width: 100%;
  }
  
  .checklist-container {
    padding-left: 0;
    row-gap: 1px;
  }

  .checklist-item {
    width: 100% !important;
  }
  
  ::v-deep .modal-body {
    padding: 0px 15px;
  }
  
  .green-btn, .red-btn {
    margin-left: 10px;
  }
}

@media screen and (max-width: 450px) {
  .header-section {
    font-size: 16px;
  }
  
  .file-name {
    font-size: 13px;
  }
  
  .file-approval-dropdown {
    height: 36px;
    font-size: 13px;
  }

  .gray-btn, .green-btn, .red-btn {
    font-size: 13px;
    padding: 8px 12px !important;
    height: 36px;
  }

  .checklist-item {
    width: 100% !important;
  }
  
}

</style>
