<script>

import ProofViewApprover from "./proof-view-approver";
import {BDropdown, BDropdownItem, BImg} from 'bootstrap-vue';
import ProofViewLang from "./proof-view-lang";

export default {
  name: "proof-view-navigation",
  components: {
    ProofViewLang,
    ProofViewApprover,
    BDropdown,
    BDropdownItem,
    BImg
  },
  props: ["avatar", "curVersionId", "versions", "approvers", "reviewers", "users", "curDisplayLang", "actives",
    "isReviewMode", "isMobile", "mode", "showApprovedButton", "approverFeedback", "archived",
    "allowApproveWithChanges", "allowApproveIndividualFiles", "displayAllApprovers", "allowDownload", "disabled"
  ],
  data() {
    return {};
  },
  mounted() {
    this.$emit('height-changed', this.$el.offsetHeight);
  },
  methods: {
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    }
  },
  computed: {
    approvedButtonOpacity() {
      return this.approverFeedback === null && !this.archived ? 1 : 0.4;
    },
  }
}


</script>

<template>
  <b-navbar class="navbar-expand-lg py-0 bg-body-tertiary bg-ashore-white border-bottom border-gray-100 flex-shrink-0 px-0">
    <b-container fluid :class="isMobile ? 'row flex-wrap m-0 d-flex justify-content-between':'px-12'" id="proofViewNav">
      <b-navbar-brand href="#" :class="isMobile ? 'col-5 d-flex justify-content-start':''">
        <b-img :src="avatar" style="height: 40px"></b-img>
      </b-navbar-brand>
      <b-nav :class="isMobile ? 'mobile-group mr-0 col-6':'d-flex align-items-center gap-10 mr-lg-auto ml-lg-0 ml-auto py-10 py-lg-0'">
        <b-form-select :disabled="disabled" :value="curVersionId" :class="isMobile ? 'col-5':'flex-shrink-0 w-auto pr-5'"
                       @change="value => $emit('on-version-change', value)"
                       style="cursor: pointer; border: 1px solid #B5B5B8;"
        >
          <b-form-select-option v-for="v in versions" :key="v.proofVersionId" :value="v.proofVersionId">
            v{{ v.versionNumber }}
          </b-form-select-option>
          <b-form-select-option key="compare" value="compare" v-if="versions.length > 1 && !isMobile">
            <b-button variant="success" class="ml-2" size="sm">
              {{ getLabel('review_screen_labels', 'compare_versions') }}
            </b-button>
          </b-form-select-option>
        </b-form-select>
        <b-dropdown v-show="allowDownload" class="download-dropdown " :class="isMobile ? 'col-2 pl-1 pr-0':''">
          <template #button-content>
            <b-img src="/img/review/icons/download.svg" style="height: 23px; width: 23px"></b-img>
          </template>
          <b-dropdown-item @click="$emit('on-download-this-file')"
                           class="text-decoration-none align-items-center text-gray-700 py-2 px-3">
            <span>{{ getLabel('review_screen_labels', 'download_single_file') }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="$emit('on-download-all-files')"
                           class="text-decoration-none align-items-center text-gray-700 py-2 px-3">
            <span>{{ getLabel('review_screen_labels', 'download_all_files') }}</span>
          </b-dropdown-item>
        </b-dropdown>
        <proof-view-lang v-if="isMobile" :is-mobile="isMobile" :cur-display-lang="curDisplayLang" v-on="$listeners"/>
      </b-nav>
      <b-nav v-show="(mode===0 && isReviewMode) || (displayAllApprovers && !isMobile)"
             :class="isMobile ? 'col-12 border-top p-2 flex-fill': 'd-flex navbar-btn-container align-items-center align-self-stretch py-10 gap-3 px-lg-3 border-end border-gray-100 order-4 order-lg-3'">
        <!--  Approvers  -->
        <div v-show="displayAllApprovers && !isMobile">
          <proof-view-approver :approvers="approvers" :users="users" :reviewers="reviewers" :actives="actives" v-on="$listeners"/>
        </div>
        <!-- Approval Buttons -->
        <div id="approval-buttons" v-show="isReviewMode" :class="isMobile ? 'col-12 px-0':'d-flex flex-column justify-content-center flex-md-row gap-2 flex-fill'">
          <b-button
              v-show="mode === 0 && showApprovedButton"
              :style="{opacity: approvedButtonOpacity}"
              :class="isMobile ? 'col-12 my-1 px-0':'flex-fill'"
              @click="$emit('on-response', 1)"
              variant="success" class="flex-fill"
          >{{ getLabel('review_screen_labels', 'approve_btn') }}
          </b-button>
          <b-button
              v-show="mode === 0 && allowApproveWithChanges && !allowApproveIndividualFiles"
              :style="{opacity: approvedButtonOpacity}"
              @click="$emit('on-response', 2)"
              :class="isMobile ? 'col-12 my-1 px-0':'flex-fill'"
              variant="success" class="flex-fill"
          >{{ getLabel('review_screen_labels', 'approve_with_changes_btn') }}
            {{
              approverFeedback !== null &&
              approverFeedback.approvalChoice === 2
                  ? "&#10004;"
                  : ""
            }}
          </b-button>
          <b-button
              v-show="mode === 0 && !allowApproveIndividualFiles"
              :style="{opacity: approvedButtonOpacity}"
              @click="$emit('on-response', 0)"
              :class="isMobile ? 'col-12 my-1 px-0':'flex-fill'"
              variant="danger" class="flex-fill"
          >{{ getLabel('review_screen_labels', 'not_approved_btn') }}
            {{
              approverFeedback !== null &&
              approverFeedback.approvalChoice === 0
                  ? "&#10004;"
                  : ""
            }}
          </b-button>
          <b-button
              v-show="mode === 0 && allowApproveIndividualFiles"
              :style="{opacity: approvedButtonOpacity}"
              @click="$emit('on-response', 3)"
              :class="isMobile ? 'col-12 my-1 px-0':'flex-fill'"
              variant="dark" class="flex-fill submit-button"
          >{{ getLabel('approver_decision_modal_labels', 'submit_decision_btn') }}
            {{
              approverFeedback !== null &&
              approverFeedback.approvalChoice === 0
                  ? "&#10004;"
                  : ""
            }}
          </b-button>
        </div>
      </b-nav>
      <proof-view-lang v-if="!isMobile" :is-mobile="isMobile" :cur-display-lang="curDisplayLang" v-on="$listeners"/>
    </b-container>
  </b-navbar>
</template>


<style scoped>
.container-fluid {
  max-width: 100% !important;
  padding-right: 0;
  padding-left: 0;
}

.mobile-group {
  display: flex;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  text-align: left;
  flex-wrap: nowrap;
}

.submit-button {
  background-color: #000000;
  border: none;
}

::v-deep .btn-secondary, .btn-secondary:focus {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  color: #000;
}

::v-deep .btn-secondary, .btn-secondary:focus {
  border: 1px solid transparent !important;
  background-color: transparent !important;

}

::v-deep .download-dropdown ul {
  z-index: 1101;
}

</style>
